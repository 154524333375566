@import url(//fonts.googleapis.com/css?family=Open+Sans:700,600,300);


@font-face{
  font-family:'almoni-dl';
  font-weight:300; /*(light)*/
  font-style: normal;
  src: url('fonts/almoni-dl-aaa-300.eot');
  src: url('fonts/almoni-dl-aaa-300.eot?#iefix') format('embedded-opentype'),
  url('fonts/almoni-dl-aaa-300.ttf') format('truetype'),
  url('fonts/almoni-dl-aaa-300.woff') format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:400; /*(regular)*/
  font-style: normal;
  src: url('fonts/almoni-dl-aaa-400.eot');
  src: url('fonts/almoni-dl-aaa-400.eot?#iefix') format('embedded-opentype'),
  url('fonts/almoni-dl-aaa-400.ttf') format('truetype'),
  url('fonts/almoni-dl-aaa-400.woff') format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:700; /*(bold)*/
  font-style: normal;
  src: url('fonts/almoni-dl-aaa-700.eot');
  src: url('fonts/almoni-dl-aaa-700.eot?#iefix') format('embedded-opentype'),
  url('fonts/almoni-dl-aaa-700.ttf') format('truetype'),
  url('fonts/almoni-dl-aaa-700.woff') format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:900; /*(black)*/
  font-style: normal;
  src: url('fonts/almoni-dl-aaa-900.eot');
  src: url('fonts/almoni-dl-aaa-900.eot?#iefix') format('embedded-opentype'),
  url('fonts/almoni-dl-aaa-900.ttf') format('truetype'),
  url('fonts/almoni-dl-aaa-900.woff') format('woff');
}

html {
  margin: 0;
  padding: 0;
}
body {
  direction: rtl;
  margin: 0;
  padding: 0;
  font-family: "almoni-dl", "open sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#01537c;
  background: url("img/bg-cloud.jpg") no-repeat top center;
  background-size: 1920px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





.kv-logo {
  width: 180px;
  height: 81px;
  background: url("img/logo.png") no-repeat top right;
  background-size: 180px 81px;
  margin: 17px 0 40px 0;
}

.head-phone {
  float:left;
}

.head-phone .r1 {
  margin-top: 40px;
  font-size:15px;
  font-weight: 700;
}

.head-phone .r2 {
  font-size:28px;
  font-weight: 700;
  line-height: 20px;
}

.person {
  width: 100%;
  border:1px solid rgba(151, 151, 151, 0.3);
  background: url("img/bg-passenger.jpg") no-repeat top left;
  background-size: contain;
  padding: 35px 49px 49px;
  border-radius: 5px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.19), 0 0 2px 0 rgba(0, 0, 0, 0.26);

  margin-bottom:40px;
}

.avatar-passenger {
  width: 38px;
  height: 38px;
  background: url("img/avatar-passenger.svg") no-repeat top right;
  margin-left: 11px;
}

.pass-header {
  display: flex;
  font-size:25px;
  font-weight: 700;
  padding-bottom: 32px;
}

label {
  font-weight: 700;
}

.react-datepicker__close-icon::after {
  right: inherit !important;
  left: 7px;
}

.react-datepicker-wrapper {
  display: block!important;

  background: url("img/picker-icon.svg") no-repeat 8px 8px;
  background-size: 20px 22px;
}

.react-datepicker-wrapper input {
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  width: 100%;
  padding: .375rem .75rem;
  background: none;
}

.ui-label {
  font-size:15px;
  color:#404042;
  font-weight: normal;
  margin-bottom: 0;
}

.dropdown-toggle::after {
  position: relative;
  top:3px;
}

@media only screen and (max-width: 600px) {
  .kv-logo {
    width: 180px;
    height: 81px;
    background: url("img/logo.png") no-repeat top right;
    background-size: 180px 81px;
    margin: 17px 0 40px 0;
  }

  .head-phone {
    float:none;
  }


  .head-phone .r1 {
    margin-top: 0px;
  }

  .head-phone .r2 {
    margin-bottom:30px;
  }
}



input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-align: right;
  direction: rtl;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  text-align: right;
  direction: rtl;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  text-align: right;
  direction: rtl;
}
input:-moz-placeholder {
  /* Firefox 18- */
  text-align: right;
  direction: rtl;
}

.dp-invalid input {
  border-color:#dc3545;
}