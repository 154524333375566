.upload-control {
  height: 133px;
  width: 100%;
  border: dashed 1.5px rgba(151, 151, 151, 0.3);
  text-align: center;

  &.invalid {
    border-color:#dc3545;
  }

  .btn-upload {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .btn-text {
      color:#ff6e00;
      font-weight: 700;
    }

    .description {
      font-size:13px;
      color:#404042;
      opacity: 0.8;
      width: 150px;
      margin: 3px auto 0 auto;
      line-height: normal;
    }
    &:hover {
      .btn-text {
        text-decoration: underline;
      }
    }
  }

  .img-upload {
    width: 24px;
    height: 24px;
    background: url("../../img/upload.png") no-repeat top left;
    background-size: 24px 24px;
    margin: auto;
  }

  >.wrap {
    padding: 19px 15px;
  }

  .row1,
  .row2 {
    display: flex;
  }

  .icon-v {
    width: 13px;
    height: 10px;
    background: url("../../img/checke-green-copy.svg") no-repeat top right;
    background-size: 13px 10px;
    position: absolute;
    right: -6px;
    top: 12px;
  }

  .image-mask {
    width: 51px;
    height: 36px;
    background: rgba(243, 243, 243, 0.5);
    border-radius: 5px;
    position: relative;
    flex: none;

    .photo-icon {
      width:27px;
      height: 20px;
      background: url('../../img/photo-icon.svg') no-repeat top right;
      background-size: 27px 20px;
      position: relative;
      top: 8px;
      right: 11px;
    }
  }

  .filename {
    font-size:14px;
    font-weight: 700;
    color:#4b5657;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    line-height: 36px;
  }


  .progress-bar {
    height: 5px;
    border-radius: 2.5px;
    background: #ffe2cc;
    position: relative;
    margin-top: 11px;
    margin-left: 6px;
    flex: 1;
    .bar {
      position: absolute;
      right: 0;
      top:0;

      height: 5px;
      border-radius: 2.5px;
      background: #ff6e00;
    }
  }

  .btn-cancel {
    width: 28px;
    height: 29px;
    background: url('../../img/delet-picture.svg') no-repeat;
    background-size: 34px 34px;
    background-position: -2px -2px;
    cursor:pointer;
  }
}
